import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import HelpIcon from '@material-ui/icons/Help';
import BookIcon from '@material-ui/icons/Book';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DocTree from './components/docTree';
import DocTreemap from './components/docTreemap';
import data from './doc.json';
import './App.css';

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  chartContent: {
    flex: 1,
    padding: '15px 30px',
    height: '100%',
    display: 'table',
  },
  chartContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  right: {
    width: '440px',
    flexShrink: 0,
  },
  rightContent: {
    flexShrink: 0,
    height: '100%',
    padding: '0 20px',
    overflowY: 'auto',
    position: 'fixed',
    background: '#eaeff1',
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
  },
  title: {
    flexGrow: 1,
    marginRight: '32px',
    textAlign: 'right',
  },
  fabdiv: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
  },
  iframe: {
    borderStyle: 'none',
    width: '100%',
    height: '100%',
    minHeight: '260px',
  },
  diagPaper: { height: '100%' },
  card: {
    minWidth: 345,
    marginTop: 20,
  },
  media: {
    height: 140,
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  paper: {
    padding: '20px',
  },
  formLabel: {
    '& span': {
      color: 'white',
    },
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tabvalue: 0,
      imageSrc: 'example.png',
      hsSettings: {},
      hlSettings: {},
      diagOpen: false,
      shownTitle: 'Please select a node',
      shownArt: 'help.html',
      checkedNum: 0,
    };
  }

  _handleTabChange(event, tabvalue) {
    this.setState({ tabvalue });
  }

  handleDiagClose = () => {
    this.setState({ diagOpen: false });
  }

  handleDiagOpen = () => {
    this.setState({ diagOpen: true });
  };

  handleShowArticle = (title, href) => {
    this.setState({
      shownTitle: title,
      shownArt: `res${href}`,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <div style={{ minHeight: '48px' }}>
          <AppBar className={classes.appBar}>
            <Tabs value={this.state.tabvalue}
              onChange={this._handleTabChange.bind(this)}
              variant="scrollable"
              scrollButtons="on">
              <Tab label="Treemap" />
              <Tab label="Tree" />
            </Tabs>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={false}
                  onChange={(e) => { this.setState({ checkedNum: +e.target.checked }); }}
                  value="checkedNum"
                  color="secondary"
                />
              }
              label={this.state.checkedNum ? 'NUMBER' : 'TITLE'}
              className={classes.formLabel}
            />
            <Typography variant="h3" color="inherit" className={classes.title}>
              <BookIcon fontSize="large"/> Shakespeare
            </Typography>
          </AppBar>
        </div>
        <main className={classes.mainContent}>
          <div className={classes.appContent}>
            <div className={classes.chartContent}>
              <Grid container className={classes.chartContainer}
                alignContent='center' direction='column' justify='center' spacing={8}>
                {this.state.tabvalue === 0
                  && <Grid item>
                    <DocTreemap data={data}
                      handleShowArticle={this.handleShowArticle}
                      settings={{
                        width: 900,
                        height: 700,
                        select: this.state.checkedNum,
                      }}/>
                  </Grid>}
                {this.state.tabvalue === 1
                    && <Grid item>
                      <DocTree data={data}
                        handleShowArticle={this.handleShowArticle}
                        settings={{
                          width: 900,
                          height: 700,
                          select: this.state.checkedNum,
                        }}/>
                    </Grid>}
              </Grid>
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.rightContent}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image="shake.png"
                  title="Shakespeare"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Shakespeare
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    William Shakespeare was an English poet, playwright and actor,
                    widely regarded as the greatest writer in the English language
                    and the world's greatest dramatist.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Total chars: {data.chars}; Maximun depth: 4.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" href="http://shakespeare.mit.edu/">
                    Data source
                  </Button>
                  <Button size="small" color="primary" href="https://en.wikipedia.org/wiki/William_Shakespeare">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
              <Divider className={classes.divider}/>
              <Paper className={classes.paper}>
                <Typography variant="h5" component="h2">
                  {this.state.shownTitle}
                </Typography>
                <iframe src={`${this.state.shownArt}`}
                  title='content'
                  className={classes.iframe}
                  style={{
                    visibility: this.state.shownArt ? 'visible' : 'hidden',
                  }}
                > </iframe>
              </Paper>
            </div>
          </div>
        </main>
        <Dialog
          open={this.state.diagOpen}
          onClose={this.handleDiagClose}
          scroll="paper"
          fullWidth
          classes={{ paper: classes.diagPaper }}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">README</DialogTitle>
          <DialogContent>
            <iframe src='help.html' title='Readme' className={classes.iframe}> </iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDiagClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.fabdiv}>
          <Fab color="primary" aria-label="Add"
            onClick={this.handleDiagOpen}>
            <HelpIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(App);
