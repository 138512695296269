import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as d3 from 'd3';

const styles = {
  node: {
    '& circle': {
      fill: '#fff',
      stroke: '#3f51b5',
      strokeWidth: '1.5px',
    },
    cursor: 'pointer',
  },
  link: {
    fill: 'none',
    stroke: '#555',
    strokeOpacity: 0.6,
    strokeWidth: '1.5px',
  },
  tooltip: {
    position: 'fixed',
    padding: '8px',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: '3px',
    '& p': {
      margin: '0',
      textAlign: 'left',
    },
    zIndex: 1000,
  },
};

class DocTree extends Component {
  constructor(props) {
    super(props);
    this.name = this.constructor.name.toLowerCase();
    this.id = _.uniqueId(`${this.name}-`);
    this.defaultSettings = {
      width: 400,
      height: 200,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      textMargin: { top: 10, right: 10, bottom: 10, left: 120 },
      select: 0,
      maxDepth: 4,
    };
    this.settings = _.merge(this.defaultSettings, props.settings);
    this.state = {
      tooltip: {
        x: 0,
        y: 0,
        opacity: 0,
        hidden: true,
      },
    };
  }

  componentDidUpdate() {
    if (this.settings.select !== this.props.settings.select) {
      this.settings = _.merge(this.defaultSettings, this.props.settings);
      this.mainGroup.selectAll('*').remove();
      this.update(this.root);
    }
  }

  componentDidMount() {
    this.initChart();
    this.update(this.root);
  }

  initChart() {
    this.svg = d3.select(this.container)
      .append('svg')
      .attr('width', this.settings.width)
      .attr('height', this.settings.height);
    this.mainGroup = this.svg.append('g')
      .attr('transform', `translate(${this.settings.margin.left
        + this.settings.textMargin.left},${this.settings.margin.top
        + this.settings.textMargin.top})`);
    const sts = this.settings;
    const widthAval = sts.width - sts.margin.left - sts.margin.right
                    - sts.textMargin.left - sts.textMargin.right;
    const heightAval = sts.height - sts.margin.top - sts.margin.bottom
                    - sts.textMargin.top - sts.textMargin.bottom;
    this.tree = d3.tree()
      .size([heightAval, widthAval - 200])
      .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);
    this.root = d3.hierarchy(this.props.data);
    this.foldLevel(2);
    this.root.x0 = heightAval / 2;
    this.root.y0 = 0;
  }

  foldLevel = (level) => {
    const nds = this.root.descendants();
    _.forEach(nds, (nd) => {
      if (nd.depth >= level && nd.children) {
        nd._children = nd.children;
        nd.children = null;
      } else if (nd.depth < level && nd._children) {
        nd.children = nd._children;
        nd._children = null;
      }
    });
  }

  update = (source) => {
    const { classes } = this.props;
    const duration = 500;
    this.tree(this.root);

    const gLinks = this.mainGroup.selectAll(`.${classes.link}`)
      .data(this.root.links(), (ln) => ln.target.data.node);
    const linkEnter = gLinks.enter()
      .append('path')
      .attr('class', classes.link)
      .attr('d', d3.linkHorizontal()
        .x(source.y0)
        .y(source.x0));
    const linkUpdate = linkEnter.merge(gLinks);
    linkUpdate
      .transition()
      .duration(duration)
      .attr('d', d3.linkHorizontal()
        .x((d) => d.y)
        .y((d) => d.x));
    gLinks
      .exit()
      .transition()
      .duration(duration)
      .attr('d', d3.linkHorizontal()
        .x(source.y)
        .y(source.x))
      .remove();

    const gNodes = this.mainGroup.selectAll(`.${classes.node}`)
      .data(this.root.descendants(), (nd) => nd.data.node);
    const nodeEnter = gNodes.enter()
      .append('g')
      .attr('class', classes.node)
      .attr('transform', `translate(${source.y},${source.x})`)
      .on('click', (d) => {
        if (!d.children && !d._children) {
          this.props.handleShowArticle(d.data.name, d.data.href);
        } else if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        this.update(d);
      });
    nodeEnter.append('circle')
      .attr('r', 6)
      .style('fill', (d) => d._children ? '#3f51b5' : '#fff')
      .style('fill-opacity', 0.5);
    nodeEnter.append('text')
      .attr('dy', '.35em')
      .attr('x', (d) => d.children ? -13 : 13)
      .attr('text-anchor', (d) => d.children ? 'end' : 'start')
      .style('visibility', (d) => d.depth >= this.settings.maxDepth ? 'hidden' : 'visible')
      .text((d) => this.settings.select ? d.data.chars : d.data.name);
    nodeEnter.on('mouseover', (dd, i, nodes) => {
      d3.select(nodes[i])
        .select('circle')
        .transition()
        .style('opacity', 0.7)
        .attr('r', 10)
        .ease(d3.easeBounceOut);
    })
      .on('mousemove', (d) => {
        const mouseX = d3.event.clientX + 15;
        const mouseY = d3.event.clientY - 30;
        this.setState({
          tooltip: {
            x: mouseX,
            y: mouseY,
            opacity: 1,
            hidden: false,
            shownTitle: d.data.name,
            shownType: d.data.type,
            shownCN: d.data.children ? d.data.children.length : 0,
            shownChars: d.data.chars,
          },
        });
      })
      .on('mouseout', (dd, i, nodes) => {
        d3.select(nodes[i])
          .select('circle')
          .transition()
          .style('opacity', 1)
          .attr('r', 6)
          .ease(d3.easeBounceOut);
        this.setState({
          tooltip: {
            opacity: 0,
            hidden: true,
          },
        });
      });

    const nodeUpdate = nodeEnter.merge(gNodes);

    nodeUpdate.transition()
      .duration(duration)
      .attr('transform', (d) => `translate(${d.y},${d.x})`);

    nodeUpdate.select('circle')
      .attr('r', 6)
      .style('fill', (d) => d._children ? '#3f51b5' : '#fff')
      .style('fill-opacity', 0.5);

    nodeUpdate.select('text')
      .attr('text-anchor', (d) => d.children ? 'end' : 'start')
      .style('visibility', (d) => d.depth >= this.settings.maxDepth ? 'hidden' : 'visible')
      .transition()
      .duration(duration)
      .attr('x', (d) => d.children ? -13 : 13);

    const nodeExit = gNodes
      .exit()
      .transition()
      .duration(duration)
      .attr('transform', `translate(${source.y},${source.x})`)
      .remove();

    nodeExit.select('circle')
      .attr('r', 1e-6);
    nodeExit.select('text')
      .style('fill-opacity', 1e-6);

    _.forEach(this.root.descendants(), (d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={`${this.name}-chart`} id={this.id}
        ref={(c) => { this.container = c; }} style={{ textAlign: 'center' }}>
        <div className={classes.tooltip} style={{
          left: this.state.tooltip.x,
          top: this.state.tooltip.y,
          opacity: this.state.tooltip.opacity,
          hidden: this.state.tooltip.hidden,
        }}>
          <p>{this.state.tooltip.shownTitle}</p>
          <p>Type: {this.state.tooltip.shownType}</p>
          <p>Children: {this.state.tooltip.shownCN}</p>
          <p>{this.state.tooltip.shownChars} chars</p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DocTree);
