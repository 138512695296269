import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as d3 from 'd3';

const styles = {
  node: {
    '& rect': {
      fill: '#fff',
      stroke: '#3f51b5',
      strokeWidth: '1.5px',
    },
    '& text': {
      fill: 'white',
    },
    cursor: 'pointer',
  },
  link: {
    fill: 'none',
    stroke: '#555',
    strokeOpacity: 0.6,
    strokeWidth: '1.5px',
  },
  tooltip: {
    position: 'fixed',
    padding: '8px',
    background: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    borderRadius: '3px',
    '& p': {
      margin: '0',
      textAlign: 'left',
    },
    zIndex: 1000,
  },
};

class DocTreemap extends Component {
  constructor(props) {
    super(props);
    this.name = this.constructor.name.toLowerCase();
    this.id = _.uniqueId(`${this.name}-`);
    this.defaultSettings = {
      width: 400,
      height: 200,
      margin: { top: 10, right: 10, bottom: 10, left: 10 },
      textMargin: { top: 10, right: 10, bottom: 10, left: 10 },
      select: 0,
      textMaxDepth: 3,
    };
    this.settings = _.merge(this.defaultSettings, props.settings);
    this.state = {
      tooltip: {
        x: 0,
        y: 0,
        opacity: 0,
        hidden: true,
      },
    };
  }

  componentDidMount() {
    this.initChart();
    this.update(this.root);
  }

  componentDidUpdate() {
    if (this.settings.select !== this.props.settings.select) {
      this.settings = _.merge(this.defaultSettings, this.props.settings);
      this.mainGroup.selectAll('*').remove();
      this.update();
    }
  }

  initChart() {
    this.svg = d3.select(this.container)
      .append('svg')
      .attr('width', this.settings.width)
      .attr('height', this.settings.height);
    this.mainGroup = this.svg.append('g')
      .attr('transform', `translate(${this.settings.margin.left
        + this.settings.textMargin.left},${this.settings.margin.top
        + this.settings.textMargin.top})`);
    const sts = this.settings;
    const widthAval = sts.width - sts.margin.left - sts.margin.right
                    - sts.textMargin.left - sts.textMargin.right;
    const heightAval = sts.height - sts.margin.top - sts.margin.bottom
                    - sts.textMargin.top - sts.textMargin.bottom;
    this.treemap = d3.treemap()
      .size([widthAval, heightAval])
      .padding(3);
    this.root = d3.hierarchy(this.props.data);
    this.root.sum((d) => (d.children && d.children.length) ? 0 : d.chars);
    this.foldLevel(1);
  }

  color = d3.scaleOrdinal(d3.schemeCategory10);

  foldLevel = (level) => {
    const nds = this.root.descendants();
    _.forEach(nds, (nd) => {
      if (nd.depth >= level && nd.children) {
        nd._children = nd.children;
        nd.children = null;
      } else if (nd.depth < level && nd._children) {
        nd.children = nd._children;
        nd._children = null;
      }
    });
  }

  update = () => {
    const { classes } = this.props;
    const duration = 500;
    this.treemap(this.root);
    const nodes = this.root.descendants();

    const gNodes = this.mainGroup.selectAll(`.${classes.node}`)
      .data(nodes.filter((d) => !d.children), (nd) => nd.data.node);
    const nodeEnter = gNodes.enter()
      .append('g')
      .attr('class', classes.node)
      .on('click', (d) => {
        if (d3.event.altKey) {
          if (d.parent && d.parent.children) {
            d.parent._children = d.parent.children;
            d.parent.children = null;
            this.update(d);
          }
        } else if (!d.children && !d._children) {
          this.props.handleShowArticle(d.data.name, d.data.href);
        } else if (d.children) {
          d._children = d.children;
          d.children = null;
          this.update(d);
        } else {
          d.children = d._children;
          d._children = null;
          this.update(d);
        }
      });

    nodeEnter.append('rect')
      .attr('id', (d, i) => `${this.id}-rect-${i}`)
      .attr('x', (d) => d.x0)
      .attr('y', (d) => d.y0)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
      .style('fill', (d) => {
        let p = d;
        while (p.depth > 1) p = p.parent;
        return this.color(p.data.name);
      })
      .style('stroke', (d) => {
        let p = d;
        while (p.depth > 1) p = p.parent;
        return this.color(p.data.name);
      })
      .style('fill-opacity', (d) => 2.5 / (1 + d.depth));

    const nodeEnterLabel = nodeEnter.append('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`);

    nodeEnterLabel.append('defs')
      .append('clipPath')
      .attr('id', (d) => `${this.id}-mask-${d.data.node}`)
      .style('pointer-events', 'none')
      .append('rect')
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0);
    nodeEnterLabel
      .append('text')
      .attr('clip-path', (d) => `url(#${this.id}-mask-${d.data.node})`)
      .attr('dy', '1.5em')
      .style('visibility', (d) => d.depth >= this.settings.textMaxDepth ? 'hidden' : 'visible')
      .style('font-size', (d) => `${2 / (d.depth + 1)}em`)
      .selectAll('tspan')
      .data((d) => [d.data.name, `${d.value} chars`])
      .join('tspan')
      .attr('x', '0.5em')
      .attr('dy', '1.5em')
      .text((d) => d);

    nodeEnter.on('mouseover', (dd, i, nds) => {
      d3.select(nds[i])
        .select('rect')
        .transition()
        .attr('x', (d) => d.x0 - 2)
        .attr('y', (d) => d.y0 - 2)
        .attr('width', (d) => d.x1 - d.x0 + 4)
        .attr('height', (d) => d.y1 - d.y0 + 4)
        .style('opacity', 0.7)
        .ease(d3.easeBounceOut);
    })
      .on('mousemove', (d) => {
        const mouseX = d3.event.clientX + 15;
        const mouseY = d3.event.clientY - 30;
        this.setState({
          tooltip: {
            x: mouseX,
            y: mouseY,
            opacity: 1,
            hidden: false,
            shownTitle: d.data.name,
            shownType: d.data.type,
            shownCN: d.data.children ? d.data.children.length : 0,
            shownChars: d.value,
          },
        });
      })
      .on('mouseout', (dd, i, nds) => {
        d3.select(nds[i])
          .select('rect')
          .transition()
          .attr('x', (d) => d.x0)
          .attr('y', (d) => d.y0)
          .attr('width', (d) => d.x1 - d.x0)
          .attr('height', (d) => d.y1 - d.y0)
          .style('opacity', 1)
          .ease(d3.easeBounceOut);
        this.setState({
          tooltip: {
            opacity: 0,
            hidden: true,
          },
        });
      });

    const nodeExit = gNodes
      .exit()
      .transition()
      .duration(duration)
      .style('opacity', 0)
      .remove();

    nodeExit.select('text')
      .style('fill-opacity', 1e-6);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={`${this.name}-chart`} id={this.id}
        ref={(c) => { this.container = c; }} style={{ textAlign: 'center' }}>
        <div className={classes.tooltip} style={{
          left: this.state.tooltip.x,
          top: this.state.tooltip.y,
          opacity: this.state.tooltip.opacity,
          hidden: this.state.tooltip.hidden,
        }}>
          <p>{this.state.tooltip.shownTitle}</p>
          <p>Type: {this.state.tooltip.shownType}</p>
          <p>Children: {this.state.tooltip.shownCN}</p>
          <p>{this.state.tooltip.shownChars} chars</p>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DocTreemap);
